<template>
  <el-dialog title="Cadastrar Livro Diário" v-model="shouldShow">
    <el-form :disabled="!isNew">
      <el-row type="flex" justify="start">
        <h4>Quem está trabalhando:</h4>
      </el-row>

      <BaseInput
        type="textarea"
        v-model="farmDailyInfo.workers"
        placeholder="Digite quais funcionários estão trabalhando"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Quem faltou:</h4>
      </el-row>

      <BaseInput
        type="textarea"
        v-model="farmDailyInfo.missing_workers"
        placeholder="Digite quais funcionários faltaram no serviço"
      ></BaseInput>

      <!-- <el-select
        remote
        filterable
        multiple
        clearable
        v-model="farmDailyInfo.workers"
        size="medium"
        value-key="uid"
        :remote-method="fetchWorkers"
        :loading="!employees"
      >
        <el-option
          v-for="item in Workers"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select> -->

      <el-row type="flex" justify="start">
        <h4>Programação do dia:</h4>
      </el-row>

      <BaseInput
        type="textarea"
        v-model="farmDailyInfo.activities"
        placeholder="Digite quais atividades serão realizadas no dia"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Problemas que estão ocorrendo:</h4>
      </el-row>

      <BaseInput
        type="textarea"
        v-model="farmDailyInfo.problems"
        placeholder="Digite algum problema que está ocorrendo agora no início"
      ></BaseInput>
    </el-form>

    <el-form v-if="!isNew" :disabled="!!farmDaily?.close_at">
      <el-row type="flex" justify="start">
        <h4>Nascimentos:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.births"
        placeholder="Caso houve nascimento, informe o numero dos animais."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Mortes:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.deaths"
        placeholder="Em caso de morte, informe o número dos animais."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Doenças:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.diseases"
        type="textarea"
        placeholder="Informe animais doentes."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Problemas com maquinário:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.machinery_problems"
        type="textarea"
        placeholder="Informe problemas com maquinário."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Carregamento de leite:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.milk_load"
        placeholder="Quantos litros de leite foram carregados."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Total de vacas no leite:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.milk_cattles"
        type="number"
        placeholder="Informe a quantidade"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de ração:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de novilha:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.new_animals"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de ração bezerra:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_animals"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de fiber:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_fiber"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de casquinha de soja:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_soyo"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de bola azevem:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_azevem"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de bola aveia:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_oat"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Recebimento de bola trigo/palha:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.incoming_food_wheat"
        placeholder="Informe quantidade e valor da nota."
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Ocorrências com funcionário:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.occurrences"
        placeholder="Houve alguma falta ou ocorrência com funcionário?"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Ocorrência com maquinário:</h4>
      </el-row>

      <BaseInput
        v-model="farmDailyInfo.machinery_occurrences"
        placeholder="Ocorreu algum problema com maquinário?"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com alimentação gado:</h4>
      </el-row>

      <BaseInput type="money" v-model="farmDailyInfo.cost_food"></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com medicação gado:</h4>
      </el-row>

      <BaseInput
        type="money"
        v-model="farmDailyInfo.cost_medication"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com manutenção trator:</h4>
      </el-row>

      <BaseInput
        type="money"
        v-model="farmDailyInfo.cost_maintanance"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com manutenção geral:</h4>
      </el-row>

      <BaseInput
        type="money"
        v-model="farmDailyInfo.cost_general_maintanance"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com ordenha:</h4>
      </el-row>

      <BaseInput type="money" v-model="farmDailyInfo.cost_milking"></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com combustível:</h4>
      </el-row>

      <BaseInput type="money" v-model="farmDailyInfo.cost_fuel"></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Gastos com sêmem:</h4>
      </el-row>

      <BaseInput
        type="money"
        v-model="farmDailyInfo.cost_reproduction"
      ></BaseInput>

      <el-row type="flex" justify="start">
        <h4>Relatório geral do dia:</h4>
      </el-row>

      <BaseInput
        type="textarea"
        v-model="farmDailyInfo.daily_report"
        placeholder="Ocorreu algum problema com maquinário?"
      ></BaseInput>
    </el-form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="closeModal"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          :disabled="!!farmDaily?.close_at"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import FarmDailyService from "../../services/farm_dailies";
import EmployeeService from "../../services/employees";
import BaseInput from "../../components/BaseInput.vue";
import { dateFormatter } from "../../utils/formatters";
export default {
  props: ["showModal", "farmDaily"],
  emits: ["close-modal", "update-filters"],
  components: { BaseInput },
  data() {
    return {
      farmDaily_: this.filters || {},
      farmDailyInfo: {},
      isLoadingSave: false,
      employees: null,
      dateFormatter,
    };
  },
  watch: {
    farmDaily: {
      handler(v) {
        if (v) {
          this.farmDailyInfo = v.info;
        } else {
          this.farmDailyInfo = {};
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchWorkers();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Workers() {
      return this.employees || [];
    },
    isNew() {
      return !this.farmDaily;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async fetchWorkers(v) {
      const queryParams = {};
      if (v) queryParams.searchName = v;

      const { employees } = await EmployeeService().index(queryParams);

      if (employees) this.employees = employees;
    },
    closeModal() {
      this.farmDaily_ = {};
      this.shouldShow = false;
    },
    async createFarmDaily() {
      const { farm_daily } = await FarmDailyService().create({
        info: this.farmDailyInfo,
        created_by: this.$store.state.user.uid,
      });

      if (farm_daily) {
        this.$emit("should-update");
        this.closeModal();
      }
    },
    async updateFarmDaily() {
      const { farm_daily } = await FarmDailyService(this.farmDaily.uid).update({
        info: this.farmDailyInfo,
        close_at: new Date(),
      });

      if (farm_daily) {
        this.$emit("should-update");
        this.closeModal();
      }
    },
    save() {
      if (this.isNew) {
        this.createFarmDaily();
      } else {
        this.updateFarmDaily();
      }
    },
  },
  name: "FarmDailyModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
