<template>
  <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ $route.name }}</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          :disabled="!CanCreateNewFarmDaily"
          @click="openFarmDailyModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="FarmDailies"
      style="width: 100%; z-index: 0"
    >
      <el-table-column
        prop="created_at"
        label="data cadastro"
      ></el-table-column>
      <el-table-column prop="user.name" label="usuário"></el-table-column>

      <el-table-column label="horas em andamento">
        <template #default="{ row: farmDaily }">
          {{ calcWorkingHours(farmDaily) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="info.problems"
        label="problemas no serviço"
      ></el-table-column>
      <el-table-column label="ações">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                title="Editar livro diário"
                circle
                @click="openFarmDailyModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteFarmDaily(cat.row)"
                title="
              Remover livro diário?
            "
                ><template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    title="Apagar livro diário"
                    circle
                  >
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <farm-daily-modal
      :showModal="showFarmDailyModal"
      :farmDaily="farmDaily"
      @should-update="() => toggleIsLoading() | fetchFarmDailies()"
      @close-modal="() => (showFarmDailyModal = false)"
    ></farm-daily-modal>
  </el-card>
</template>
<script>
import FarmDailyService from "../services/farm_dailies";
import FarmDailyModal from "./modals/FarmDailyModal.vue";
import { notifySuccess, notifyError } from "../utils/notifiers";
export default {
  name: "FarmDailies",
  data() {
    return {
      farm_dailies: null,
      showFarmDailyModal: false,
      farmDaily: null,
      isLoading: true,
    };
  },
  components: { FarmDailyModal },
  computed: {
    FarmDailies() {
      return this.farm_dailies?.data || [];
    },
    CanCreateNewFarmDaily(){
      return this.FarmDailies.every(daily => !!daily.close_at);
    }
  },
  mounted() {
    this.fetchFarmDailies();
  },
  methods: {
    calcWorkingHours(farmDaily) {
      const createdAt = new Date(farmDaily?.created_at);
      const closedAt = new Date(farmDaily?.close_at || Date.now());

      const diff = closedAt.getTime() - createdAt.getTime();

      return Math.max(Math.floor(diff / (1000 * 60 * 60)), 0);
    },
    toggleIsLoading() {
      this.isLoading = !this.isLoading;
    },
    openFarmDailyModal(farmDaily) {
      this.farmDaily = farmDaily;
      this.showFarmDailyModal = true;
    },
    async deleteFarmDaily(_farmDaily) {
      const { farm_daily, error } = await FarmDailyService(
        _farmDaily.uid
      ).delete();

      if (farm_daily){
        notifySuccess(farm_daily.message);
        this.fetchFarmDailies();
      }
      else notifyError(error.message);
    },
    async fetchFarmDailies() {
      const { farm_dailies } = await FarmDailyService().index();

      if (farm_dailies) this.farm_dailies = farm_dailies;

      this.isLoading = false;
    },
  },
};
</script>
