import http from "./http";
export default (employeeId) => ({
  async index(filters) {
    return http
      .index("employees", filters)
      .then((json) => ({ employees: json }))
      .catch(async (e) => ({ employees: null, error: await e }));
  },
  async get() {
    return http
      .get("employees", employeeId)
      .then((json) => ({ employee: json }))
      .catch(async (e) => ({ employee: null, error: await e }));
  },
  async create(data) {
    return http
      .post("employees", data)
      .then((json) => ({ employee: json }))
      .catch(async (e) => ({ employee: null, error: await e }));
  },
  async update(data) {
    return http
      .put("employees", employeeId, data)
      .then((json) => ({ employee: json }))
      .catch(async (e) => ({ employee: null, error: await e }));
  },
  async delete() {
    return http
      .delete("employees", employeeId)
      .then((json) => ({ employee: json }))
      .catch(async (e) => ({ employee: null, error: await e }));
  },
  Control: (controlId) => ({
    async index(filters) {
      return http
        .index(`employees/${employeeId}/controls`, filters)
        .then((json) => ({ controls: json }))
        .catch(async (e) => ({ controls: null, error: await e }));
    },
    async get() {
      return http
        .get(`employees/${employeeId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`employees/${employeeId}/controls`, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`employees/${employeeId}/controls`, controlId, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`employees/${employeeId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
  }),
});
