import http from "./http";
export default (farmDailyId) => ({
  async index(filters) {
    return http
      .index("farm_dailies", filters)
      .then((json) => ({ farm_dailies: json }))
      .catch(async (e) => ({ farm_dailies: null, error: await e }));
  },
  async get() {
    return http
      .get("farm_dailies", farmDailyId)
      .then((json) => ({ farm_daily: json }))
      .catch(async (e) => ({ farm_daily: null, error: await e }));
  },
  async create(data) {
    return http
      .post("farm_dailies", data)
      .then((json) => ({ farm_daily: json }))
      .catch(async (e) => ({ farm_daily: null, error: await e }));
  },
  async update(data) {
    return http
      .put("farm_dailies", farmDailyId, data)
      .then((json) => ({ farm_daily: json }))
      .catch(async (e) => ({ farm_daily: null, error: await e }));
  },
  async delete() {
    return http
      .delete("farm_dailies", farmDailyId)
      .then((json) => ({ farm_daily: json }))
      .catch(async (e) => ({ farm_daily: null, error: await e }));
  },
});
